/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, OnInit, Input, Self, Inject } from '@angular/core';
import { form } from '@leandredev/adaptivai-ng16-lib';
import { FormControlBase } from '@leandredev/common-ng16/form';
import { ActivatedRoute } from '@angular/router';
import { UIComponentState } from '@leandredev/common-ng16/infraService';
import { UiForm } from '@leandredev/common-ng16/components';

@Component({
  selector: 'app-ui-form-userclientinvitation',
  templateUrl: './UserClientInvitation.component.html',
  styleUrls: ['./UserClientInvitation.component.scss'],
  providers: [UIComponentState]
})
export class UserClientInvitationFormComponent extends UiForm<form.UserClientInvitation> implements OnInit {
  private mode: string = '';

  @Input() override id = '';
  constructor(
    @Self() protected override uIComponentState: UIComponentState,
    public activeRoute: ActivatedRoute
  ) {
    super(uIComponentState);

    // check edit mode or create mode
    this.activeRoute.data.subscribe({
      next: (data) => {
        if (data && data.mode != '') {
          switch (data.mode) {
            case 'edit':
              this.mode = 'edit';

              break;
            case 'new':
              this.mode = 'new';

              break;
          }
        }
      }
    });
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.uIComponentState.id = this.id;
    Object.assign(this.state, this.uIComponentState.state);
    Object.assign(this.uIComponentState.state, this.state);
    this.state = this.uIComponentState.state;

    switch (this.mode) {
      case 'edit':
        this.setupEdition();
        break;
      case 'new':
        this.setupNew();
        break;
    }
  }

  public fieldClassHumanName: { [key: string]: string } = {};

  public classChange(value: string, control: FormControlBase): void {
    let oldVal: any;
    oldVal = control.value;
    if (!oldVal) {
      oldVal = {};
    }
    oldVal._class = value;
    control.patchValue(oldVal);
  }

  private setupEdition(): void {}
  private setupNew(): void {
    const newDate = new Date().toISOString();
  }
}
